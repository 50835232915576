@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
}
#desc{
    background: url(./assets/focused-workaholic-african-american-businesswoman-working-company-financial-charts-presentation\ 1.png);
    background-repeat:no-repeat;
    background-size:cover; 
    background-position:center;
}

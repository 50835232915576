.aboutContainer{
        background:url(../../assets/Desktop\ -\ 9.png);
        background-repeat:no-repeat;
        background-size:cover; 
        background-position:center;
        
}
.overlay{
        background-color: rgba(0, 0, 0, 0.77);
          
}


@media(min-width: 768px){
 .aboutContainer {
                margin-top: 8vh;
        }
}

@media(min-width: 1024px){
 .aboutContainer {
                margin-top: 5vh;
        }
}

@media(min-width: 1200px){
 .aboutContainer {
                width: 100%;
                height:100vh;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: 10vh;
        }
 .overlay {
                width: 100%;
                height:100vh;
                display: flex;
                align-items: center;
                justify-content: space-around;
        }
}
@media(min-width: 1400px){
 .aboutContainer {
                margin-top: 3vh;
        }
}
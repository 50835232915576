.container{
    width: 100%;
    height:50vh;
    background: linear-gradient(rgba(253, 239, 239, 0.2), rgba(0, 0, 0, 0.20)),url(../../assets/online-data-storage-document-management-technology\ 1.png);
    background-repeat:no-repeat;
    background-size:cover; 
    background-position:center;
}
.vidPlayer{
    width: 100%;
    height:300px;
    object-fit: fill;
}
.overlay{
    width: 100%;
    height: 100%;
    background-color: #0000008c;
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center;
    padding: 20px;
}

@media(min-width: 768px){
    .container{
        height: 75vh;
        margin-top: 0;
    }
    .vidPlayer{
        height: 75vh;
        margin-top: 0;
    }
    .overlay{
        padding: 100px;
    }
}
@media(min-width: 1024px){
    .container{
        height: 700px;
        margin-top: 0;
    }
    .vidPlayer{
        height: 700px;
        margin-top: 0;
    }
    .overlay{
        padding: 50px;
        justify-content: flex-start;
    }
}

@media(min-width: 1280px){
    .container{
        height: 100vh;
        margin-top: 7vh;
    }
    .vidPlayer{
        height: 90vh;
        margin-top: 10vh;
       
    }
    .overlay{
        justify-content: flex-start;
        padding: 0;
    }
}
@media screen and (min-width:1440px){
    .container{
        height: 100vh;
    }
    .vidPlayer{
        height: 100vh;
    }
}
@media screen and (min-width:2200px){
    .container{
        margin-top: 5vh;
    }
    .vidPlayer{
        margin-top: 5vh;
    }
}

@media screen and (orientation:landscape) and (max-width: 900px){
    .break{
        display: none;
    }
    .container{
        height:400px;
    }
    .vidPlayer{
        height:400px;
    }
    .textCont{
        height: 250px;
        width: 60vw;
    }
    .heroHead{
        font-size: 18px;
        line-height: 24px;
        width: 100%;
    }
    .heroText{
        font-size: 12px;
        line-height: 20px;
    }
    .btns{
        width: 70%;
    }
    .gsBtn{
        width: 100px;
        height: 30px;
        font-size: 12px;
    }
    .wvBtn{
        font-size: 16px;
    }
   
}
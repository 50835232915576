.aboutContainer{
   background-color: rgba(191, 191, 191, 0.13);
}
.advantagesContainer{
   background-color: rgba(196, 244, 215, 1);
}
.comment{
   background:url(../assets/Desktop\ -\ 4.png);
   background-repeat:no-repeat;
   background-size:cover; 
   background-position:center;
}
.commentOverlay{
   background-color: rgba(255, 255, 255, 0.966);
}

@media screen and (min-width:1400px) {
    /* .img{
       height: 45%;
    }
    .vector{
        height: 50%;
        bottom: 30%;
     } */
     .aboutVadoc{
        height: 32%;
     }

}

@media screen and (min-width:1800px) {
   
     .patnertext{
      font-size: calc(24px + 0.5vw);
     }
     .aboutVadoc{
      height: 45%;
     }
     .about{
      font-size: calc(36px + 0.8vw);
     }
     .aboutp{
      font-size: calc(16px + 0.5vw);
      line-height: calc(22px + 1.5vh);
     }
     

}

@media screen and (orientation:landscape) and (max-width: 900px) {
   .AboutUsDiv{
      height:800px;
      margin-bottom: 100px;
   }
   .features{
      height:auto;
   }
   .aboutContainer{
      height: 600px;
   }
   .aboutimg{
      height:70%;
      width: 70%;
      margin: auto;
      display: flex;
   }
   .vector{
      width: 60%;
   }
   .img{
      width: 60%;
      right: 25%;
   }
   .comment, .commentOverlay{
      height: 300px
   }
   .advantages{
      height: 700px;
   }
   .advPic{
      height: 80%;
   }
} 
.nav{
  padding-top: 2rem/* 8px */;
  padding-bottom: 1.5rem/* 8px */;
  display: flex;
  justify-content: flex-end;
}

.navbars{
  margin-top: 20px;
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: space-between;
}

.navbars.active{
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}


.navItemsDiv{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  font-family: 'Open Sans', "sans serif";
}
.navItemsDiv2{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  font-family: 'Open Sans', "sans serif";
}

.navItems:hover{
  --tw-text-opacity: 1;
  color: rgb(81 221 17 / var(--tw-text-opacity));
}

.joinUsBtn{
  --tw-bg-opacity: 1;
  background-color: rgb(81 221 17 / var(--tw-bg-opacity));
  height: 40px;
  width: 119px;
  border-radius: 5px;
  margin-right: 10%;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 16px;
  font-weight: 700;
}
.joinUsBtn:hover{
  background-color: #1FBE54;
}
.joinUsBtn2{
  --tw-bg-opacity: 1;
  background-color: rgb(81 221 17 / var(--tw-bg-opacity));
  height: 40px;
  width: 119px;
  border-radius: 5px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 16px;
  font-weight: 700;
}
.joinUsBtn2:hover{
  background-color: #1FBE54;
}



@media screen and (max-width:700px){
  .nav {
    background-color: black;
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav.active {
    left: 0;
    transition: 350ms;
    z-index: 15;
    width: 70%;
  }

  .navbars{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 60%;
  }

  /* .navItemsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    height: 70%;
    margin-right: 50%;
  } */

  .joinUsBtn2{
    width: 100px;
    margin-right: 50%;

  }
}

@media screen and (min-width:1536px){
  .joinUsBtn2{
    font-size: 24px;
    width: 150px;
    height: 50px;
  }
}
@media screen and (min-width:2200px){
  .navItemsDiv{
    font-size: 28px;
  }
  .joinUsBtn2{
    font-size: 28px;
    width: 150px;
    height: 50px;
  }
}



@media (orientation: landscape) {
  .carousel {
    height: 300px;
  }
  .nav-menu{
    height: 100vh;
  }
}

@media screen and (orientation:landscape) and (max-width: 900px){
  .fa-bars{
    display: none;
  }
  .largeLogo{
    width: 20%;
  }
  .navsLandscape{
    width: 60%;
  }
  .nav{
    padding-top: 1rem/* 8px */;
    padding-bottom: 1rem/* 8px */;
    display: flex;
    justify-content: flex-end;
  }
  .navItemsDiv{
    width: 60%;
    font-size: 13px;
  }

  .joinUsBtn2{
    height: 30px;
    width: 75px;
    font-size: 13px;
  }
 
}
